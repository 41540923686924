// TODO: Moday image didn't change when it became monday again, fixed on refresh. Today rendered but the data for mon didnt update.
import Navbar from "./Navbar.js";
import Word from "./Word.js";
import Footer from "./Footer.js";
import "./Home.css";
import React, {useEffect, useState} from "react";
import nlp from 'compromise';
//import natural from 'natural';
//const stemmer = natural.PorterStemmer;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import {
  getFirestore, doc, updateDoc, getDocs, collection
} from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuayZTEcob0Kr8EqJegKlfIui1AUetQaU",
  authDomain: "daily-dictionary-b4e7f.firebaseapp.com",
  projectId: "daily-dictionary-b4e7f",
  storageBucket: "daily-dictionary-b4e7f.firebasestorage.app",
  messagingSenderId: "1027372630124",
  appId: "1:1027372630124:web:6eb2fa90337165b05a9849",
  measurementId: "G-BWT0VYX7SG"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();

const docRef = doc(db, "app", "9MbO4rDRH1XHKcSgaiP2");

const colRef = collection(db, "app");

let now = new Date();

let sec = now.getSeconds();

let min = now.getMinutes();

let hrs = now.getHours();

let days = now.getDay();

let displayedSec = 59 - sec;

(sec > 49) ? displayedSec = "0" + displayedSec : displayedSec += "";

let displayedMin = 59 - min;

(min > 49) ? displayedMin = "0" + displayedMin : displayedMin += "";

let displayedHrs = 23 - hrs;

(hrs > 13) ? displayedHrs = "0" + displayedHrs : displayedHrs += "";

export default function Home() {

    const [dailyTimer, setDailyTimer] = useState(`${displayedHrs}:${displayedMin}:${displayedSec}`);
    const [day, setDay] = useState(days);
    const [wordOfDay, setWordOfDay] = useState({
        "word": "Word of the Day",
        "defs": [{"partOfSpeech": "Noun", "definitions": ["def1", "def2", "def3"]}, {"partOfSpeech": "Verb", "definitions": ["def1", "def2", "def3"]}],
        "img": "./logo192.png",
    });
    
    useEffect(() => {

        (async function () {

            setInterval(() => {
            
                now = new Date();
    
                sec = now.getSeconds();
    
                min = now.getMinutes();
    
                hrs = now.getHours();
    
                days = now.getDay();
    
                
                displayedSec = 59 - sec;
    
                (sec > 49) ? displayedSec = "0" + displayedSec : displayedSec += "";
    
                displayedMin = 59 - min;
    
                (min > 49) ? displayedMin = "0" + displayedMin : displayedMin += "";
    
                displayedHrs = 23 - hrs;
    
                (hrs > 13) ? displayedHrs = "0" + displayedHrs : displayedHrs += "";
    
                setDailyTimer(`${displayedHrs}:${displayedMin}:${displayedSec}`);
                setDay(days);
            }, 1000);

            const fetchDayFirestore = async function () {
                try {
                    const data = await getDocs(colRef);
                    const filteredData = data.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }));
        
                    // Assume you want to get `timer.day` from the first document
                    return filteredData[0]?.timer.day;
                } catch (err) {
                    console.error(err);
                }
            };

            const dayFirestore = await fetchDayFirestore();

            if (days !== dayFirestore) {
                await fetchWord();
            }

            (async function wordDataFirestore () {
                try {
                    const data = await getDocs(colRef);
                    const filteredData = data.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }));
        
                    // Assume you want to get `timer.day` from the first document
                    setWordOfDay({
                        "word": filteredData[0]?.media.wordOfDay.word,
                        "defs": filteredData[0]?.media.wordOfDay.definitions,
                        "img": filteredData[0]?.media.wordOfDay.image
                    })

                } catch (err) {
                    console.error(err);
                }
            })();

            // Updates day number in Firestore
            (async function writeDayNumber() {
                try {
                    await updateDoc(docRef, {
                        "timer.day": days
                    });
                } catch (err) {
                    console.error(err);
                }
        
            })();

        })();
        
    }, [day])

    return(
        <>
            <Navbar />

            <div className="word-of-day-content-container">
                <>{(wordOfDay.word !== "Word of the Day") ? (<Word word={wordOfDay.word} definitions={wordOfDay.defs} image={wordOfDay.img} object_name={"wordOfDay"} daily_timer={dailyTimer}/>) : (<div className="loading-wheel"></div>)}</>
            </div>

            <Footer />
        </>
    );
}




async function fetchWord() {
    let fetchAttempts = 0;
    // Limit fetch attempts to a max of 100 if program has unexpected issues fetching 
    while (fetchAttempts < 100) {
        try {
            const randomWordAPI = await fetch('https://random-word.ryanrk.com/api/en/word/random'); // fetches random word page from random word API
            const randomWordJSON = await randomWordAPI.json();
            const randomWord = await randomWordJSON[0];
            console.log(randomWord);
            const wordObjectAPI = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${randomWord}`); // fetches random word's definition from dictionary API
            const wordObjectJSON = await wordObjectAPI.json();
            
            const processedWord = nlp(wordObjectJSON[0].word);
            const stem = processedWord.verbs().toInfinitive().out() || wordObjectJSON[0].word;

            console.log(wordObjectJSON);
            const imageAPI = await fetch(`https://pixabay.com/api/?key=32259448-4a754c36feddda25c19fce750&q=${randomWord}&image_type=photo`);
            const imageJSON = await imageAPI.json();
            const imageURL = await imageJSON.hits[0].webformatURL;

            const definition = wordObjectJSON[0].meanings[0].definitions[0].definition;
        
            console.log(stem);

            if (!definition.includes(stem)) {
                /*
                const definitionsArray = (wordObjectJSON[0].meanings).map(
                    (meaning) => {
                        return(
                            <>
                                <h4>{ meaning.partOfSpeech }</h4>
                                <ol>{(meaning.definitions).map(obj => <li>{ obj.definition }</li>)}</ol>
                            </>
                        );
                    }
                );
                */

                const definitionsArray = wordObjectJSON[0]?.meanings?.map((meaning) => ({
                    partOfSpeech: meaning.partOfSpeech,
                    definitions: meaning.definitions.map((obj) => obj.definition),
                })) || [];

                // EX: [{"partOfSpeech": "Noun", "definitions": ["def1", "def2", "def3"]}, {"partOfSpeech": "Verb", "definitions": ["def1", "def2", "def3"]}]

                (async function writeDayNumber() {
                    try {
                        await updateDoc(docRef, {
                            [`media.wordOfDay.word`]: wordObjectJSON[0].word,
                            [`media.wordOfDay.definitions`]: definitionsArray,
                            [`media.wordOfDay.image`]: imageURL,
                        });
                    } catch (err) {
                        console.error(err);
                    }
            
                })();
                
                break;
            }
        } catch (err) {
            console.error(err);
        }
    }
}