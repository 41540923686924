import { useState } from "react";
import "./Word.css";

// Firebase initialization and imports
import { initializeApp } from "firebase/app";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCuayZTEcob0Kr8EqJegKlfIui1AUetQaU",
  authDomain: "daily-dictionary-b4e7f.firebaseapp.com",
  projectId: "daily-dictionary-b4e7f",
  storageBucket: "daily-dictionary-b4e7f.firebasestorage.app",
  messagingSenderId: "1027372630124",
  appId: "1:1027372630124:web:6eb2fa90337165b05a9849",
  measurementId: "G-BWT0VYX7SG",
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Initialize Firestore services
const db = getFirestore();
const docRef = doc(db, "app", "9MbO4rDRH1XHKcSgaiP2");


export default function Word({ word, definitions, image, object_name, daily_timer }) {
  const [imageError, setImageError] = useState(false);
  const [imageIndex, setImageIndex] = useState(0); // To track which image we're trying

  const handleImageError = async (_word, object_name) => {
    try {
      // Fetch image from Pixabay API
      const imageAPI = await fetch(
        `https://pixabay.com/api/?key=32259448-4a754c36feddda25c19fce750&q=${_word}&image_type=photo`
      );
      const imageJSON = await imageAPI.json();

      if (imageJSON.hits && imageJSON.hits.length > 0) {
        const maxAttempts = imageJSON.hits.length;
        let imageURL = null;

        async function updateImage(_i) {
            // Update Firestore with the new image URL
            await updateDoc(docRef, {
              [`media.${object_name}.image`]: imageURL,
            });
            setImageError(false); // Successfully fetched image
            setImageIndex(_i + 1); // Move to the next image for the next error
        };

        // Try loading images until we find a valid one
        for (let i = imageIndex; i < maxAttempts; i++) {
          imageURL = imageJSON.hits[i].webformatURL;
          const img = new Image();
          img.src = imageURL;

          // Check if image is valid
          img.onload =  updateImage(i);

          img.onerror = () => {
            console.log(`Image ${i + 1} failed to load, trying next.`);
            // Continue to next image
            if (i === maxAttempts - 1) {
              setImageError(true); // All attempts failed
            }
          };
        }
      } else {
        throw new Error("No images found.");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      setImageError(true); // Set error if image fetch fails
    }
  };

  return (
    <>
      <div className="today-word-content"> 
        <div className="today-word-text-content-container">
          <div className="today-word-text-content">
            <h2>{word}</h2>
            <p>New word in:</p>
            <p>{daily_timer}</p>
            <button className="read-word-button" onClick={() => readWord(word)}><img className="button-img" src={require("./images/play_audio.png")} alt="Play Audio"/></button>
            
            <div>{getDefinitions(definitions)}</div>
          </div>
        </div>

        <div className="today-word-img-content-container">
          <div className="today-word-img-content">
            {/* Conditionally render the image or fallback */}
            {imageError ? (
              <p>Error loading image. Please try again later.</p>
            ) : image ? (
              <img
                className="today-word-img"
                src={image}
                alt={`${word} depicted by Pixabay`}
                onError={() => handleImageError(word, object_name)}
              />
            ) : (
              <p>No image available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

Word.defaultProps = {
  definitions: [],
  image: null,
};

function readWord(word) {
  if (!speechSynthesis.speaking) {
    const utterance = new SpeechSynthesisUtterance(word);
    speechSynthesis.speak(utterance);
  }
}

function getDefinitions(_definitions = []) {
  return Array.isArray(_definitions)
    ? _definitions.map((meaning, index) => (
        <div key={index} className="definition-section">
          <h3>{meaning.partOfSpeech}</h3>
          <ol>
            {meaning.definitions.map((def, i) => (
              <li key={i}>{def}</li>
            ))}
          </ol>
        </div>
      ))
    : <p>No definitions available</p>;
}
