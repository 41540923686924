import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Home.js";
import OurMission from './OurMission.js';
import PrivacyPolicy from './PrivacyPolicy.js';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={ <Home /> } />
          <Route path="/our_mission" element={ <OurMission /> } />
          <Route path="/privacy_policy" element={ <PrivacyPolicy /> } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
