import "./Navbar.css";
import React, { useEffect, useRef } from "react";


export default function Navbar() {
    const homeButtonImg = useRef(null);
    const ourMissionButtonImg = useRef(null);

    useEffect(() => {
        if (window.location.pathname === "/" && homeButtonImg.current) {
            homeButtonImg.current.style.borderBottom = "2px solid black";
        } else if (window.location.pathname === "/our_mission" && ourMissionButtonImg.current) { 
            ourMissionButtonImg.current.style.borderBottom = "2px solid black";
        }
    }, [homeButtonImg, ourMissionButtonImg])

    return(
        <div className="navbar-container">
            <h1 className="navbar-title">Diction Daily <img className="daily-diction-logo" src={require('./images/daily_diction_logo.png')} alt="Diction Daily" /></h1>
            <div className="navbar-buttons">
                <button className="navbar-button" onClick={ () => handleNavbarButtonClick("Home") } title="Home"><img className="button-img" src={require('./images/home2.png')} alt="Home" ref={homeButtonImg}/></button>
                <button className="navbar-button" onClick={ () => handleNavbarButtonClick("Our Mission") } title="Our Mission"><img className="button-img" src={require('./images/about_us1.png') } alt="Our Mission" ref={ourMissionButtonImg}/></button>
            </div>
        </div>
    );
}


function handleNavbarButtonClick (_button_name) {
    if (_button_name === "Home" && window.location.pathname !== "/") {
        window.location.href = "/";
    } else if (_button_name ===  "Our Mission" && window.location.pathname !== "/our_mission") {
        window.location.href = "/our_mission";
    }
}
