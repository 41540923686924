import "./PrivacyPolicy.css";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";


export default function PrivacyPolicy() {


    return(
        <>
            <Navbar />
            <div className="content-container">
                <div className="content">
                    
                    <h2 className="header">Privacy Policy for Diction Daily</h2>
                    
                    <h3 className="header">Effective Date: December 13, 2024</h3>
                    <p className="paragraph">
                        At Diction Daily, accessible here, the privacy of our visitors is of utmost importance. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to safeguard your information. By using our website, you consent to the terms of this Privacy Policy.
                    </p>
                    
                    <h3 className="header">Information We Collect</h3>
                    <h4 className="header">1. Personal Information</h4>
                    <p className="paragraph">
                        We do not collect any personal information directly from visitors unless they voluntarily provide it (e.g., sending an email directly to Diction Daily). Examples of personal information include:
                    </p>
                    <ul className="header">
                        <li>Name</li>
                        <li>Email address</li>
                    </ul>
                    <h4 className="header">2. Non-Personal Information</h4>
                    <p className="paragraph">
                        We may collect non-personal information automatically to improve user experience. This includes:
                    </p>
                    <ul className="header">
                        <li>Browser type and version</li>
                        <li>IP address</li>
                        <li>Referring/exit pages</li>
                        <li>Date and time of access</li>
                    </ul>
                    
                    <h3 className="header">Use of Cookies</h3>
                    <p className="paragraph">
                        Diction Daily uses cookies to enhance user experience and serve personalized advertisements through third-party services, including Google AdSense. Cookies allow us to:
                    </p>
                    <ul className="header">
                        <li>Understand user preferences and improve our content</li>
                        <li>Deliver targeted advertising based on user interests</li>
                    </ul>
                    <p className="paragraph">
                        You can disable cookies through your browser settings. However, note that this may impact your experience on our website.
                    </p>

                    <h3 className="header">Google AdSense</h3>
                    <p className="paragraph">
                        We partner with Google AdSense to serve advertisements on our website. Google uses cookies, including the DoubleClick cookie, to serve ads based on your prior visits to this and other websites. You can learn more about Google’s advertising cookies here and opt out of personalized advertising <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer" >here</a>.
                    </p>

                    <h3 className="header">Third-Party Services</h3>
                    <p className="header">We use the following third-party services on our website:</p>
                    <ul className="header">
                        <li>DictionaryAPI: Provides definitions for our daily words.</li>
                        <li>Pixabay: Supplies royalty-free images for website content.</li>
                        <li>Random Word Generator: Provides a word for daily display.</li>
                    </ul>
                    <p className="paragraph">
                        These services may collect data as described in their respective privacy policies. We encourage you to review their policies for more information.
                    </p>

                    <h3 className="header">Data Protection</h3>
                    <p className="paragraph">
                        We prioritize the security of your information. While we do not actively store personal data, we use industry-standard measures to protect non-personal information from unauthorized access.
                    </p>

                    <h3 className="header">Children's Privacy</h3>
                    <p className="paragraph">
                        Diction Daily does not knowingly collect personal information from children under the age of 13. If we learn that we have collected such data, we will take immediate steps to delete it.
                    </p>

                    <h3 className="header">Your Data Rights</h3>
                    <p className="header">
                        You have the right to:
                    </p>
                    <ul className="header">
                        <li>Opt out of personalized advertising through <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer" >Google Ads Settings.</a></li>
                        <li>Disable cookies via browser settings.</li>
                        <li>Contact us if you have concerns about data collected through this website.</li>
                    </ul>
                    
                    <h3 className="header">Updates to this Policy</h3>
                    <p className="paragraph">
                        This Privacy Policy may be updated periodically. Any changes will be reflected on this page with an updated effective date.
                    </p>

                    <h3 className="header">Contact Us</h3>
                    <p className="header">
                        If you have any questions about this Privacy Policy, please contact us at:
                    </p>
                    <ul className="header">
                        <li>Email: <a href="mailto:dictiondailysite@gmail.com" target="_blank" rel="noopener noreferrer" >dictiondailysite@gmail.com</a></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}