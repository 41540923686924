import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer-content">
            <div className="api-list">
                <p>Daily Diction Powered by:</p>
                <ul>
                    <li><a href="https://dictionaryapi.dev/" target="_blank" rel="noopener noreferrer" >DictionaryAPI</a></li>
                    <li><a href="https://pixabay.com/" target="_blank" rel="noopener noreferrer" >Pixabay</a></li>
                    <li><a href="https://random-word.ryanrk.com/" target="_blank" rel="noopener noreferrer" >Random Word</a></li>
                </ul>
            </div>
            <div className="sitemap">
                <p>Sitemap:</p>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/our_mission">Our Mission</a></li>
                    <li><a href="/privacy_policy">Privacy Policy</a></li>
                </ul>
            </div>
        </div>
    );
}